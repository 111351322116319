import { defineComponent, ref, watch } from "vue";
import { ModalBox, Selector } from '@/components/UI';
import './NewScheduleModal.scss';
import useRooms from "@/compositions/useRooms";
import { SchedulePriority } from "@/types/rooms";
import { cloneDeep } from "lodash";
import CheckBox from "@/components/UI/CheckBox/CheckBox.vue";
export default defineComponent({
    name: "NewScheduleModal",
    components: {
        ModalBox,
        Selector,
        CheckBox
    },
    props: {
        modelValue: {
            type: Object,
            required: true
        }
    },
    emits: {
        close() { return true; },
        submit(_value) { return true; },
        'update:modelValue'(_value) { return true; },
        onSettingsChanged(_value) { return true; }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, emits) {
        const currentTeams = ref(cloneDeep(props.modelValue));
        const scheduleSettings = ref({
            minimize_chosen_rooms: true,
            handle_combined_rooms: currentTeams.value[0].num_slots <= 5
        });
        watch(scheduleSettings, () => {
            emits.emit('onSettingsChanged', scheduleSettings.value);
        }, { deep: true });
        const { rooms } = useRooms();
        currentTeams.value.forEach(team => {
            if (team.room_preferences === undefined) {
                team.room_preferences = {};
            }
            Object.keys(rooms.value).forEach(roomId => {
                var _a, _b;
                if (team.room_preferences[roomId] === undefined) {
                    team.room_preferences[roomId] = (_b = (_a = rooms.value[roomId]) === null || _a === void 0 ? void 0 : _a.priority) !== null && _b !== void 0 ? _b : SchedulePriority.Normal;
                }
            });
        });
        return {
            scheduleSettings,
            currentTeams,
            rooms
        };
    },
    computed: {
        options() {
            return [
                {
                    text: 'Important',
                    value: SchedulePriority.Important
                },
                {
                    text: 'Normal',
                    value: SchedulePriority.Normal
                },
                {
                    text: 'Minor',
                    value: SchedulePriority.Minor
                },
                {
                    text: "Don't schedule",
                    value: SchedulePriority.Never
                }
            ];
        },
        numSlots() {
            var _a;
            return Number((_a = this.currentTeams[0]) === null || _a === void 0 ? void 0 : _a.num_slots);
        },
        pauseToggle: {
            get() {
                return this.currentTeams[0].pause_preferences ? this.currentTeams[0].pause_preferences.length > 0 : false;
            },
            set(value) {
                if (value) {
                    this.currentTeams[0].pause_preferences = new Array(this.numSlots + 1).fill(SchedulePriority.Minor);
                    const center_slot = Math.floor(this.currentTeams[0].pause_preferences.length / 2);
                    this.currentTeams[0].pause_preferences[center_slot] = SchedulePriority.Normal;
                }
                else {
                    this.currentTeams[0].pause_preferences = [];
                }
            }
        }
    },
    methods: {
        onClose() {
            this.$emit('close');
        },
        onSubmit() {
            const referenceTeam = this.currentTeams[0];
            Object.values(this.currentTeams).forEach(team => {
                if (team !== referenceTeam) {
                    team.room_preferences = referenceTeam.room_preferences;
                    team.pause_preferences = referenceTeam.pause_preferences;
                }
            });
            this.$emit('onSettingsChanged', this.scheduleSettings);
            this.$emit('update:modelValue', this.currentTeams);
            this.$emit('submit', this.currentTeams);
        }
    }
});
